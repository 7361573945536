import React, { useCallback, useMemo } from "react";
import { SetterOrUpdater, useRecoilValue, useSetRecoilState } from "recoil";
import {
  chatState,
  sessionAtom,
  sessionStateState,
} from "../../atom/sessionAtom";
import { Chat, SessionState } from "../../model/session";
import { messagesApi } from "../../api/messagesApi";
import { gEvent } from "../../util/gtag";

export const startReviewFn = async (
  sessionId: string,
  setChat: SetterOrUpdater<Chat>,
  finishSession?: SetterOrUpdater<SessionState>,
) => {
  if (finishSession) {
    finishSession(SessionState.REVIEW);
  }
  setChat((chat) => ({ ...chat, isError: false }));

  gEvent("start_review", { sessionId });
  try {
    gEvent("success_review", { sessionId });
    const feedback = await messagesApi.getFeedback(sessionId);
    setChat((chat) => ({ ...chat, feedback }));
  } catch (e) {
    gEvent("fail_review", { sessionId });
    setChat((chat) => ({ ...chat, feedback: [], isError: true }));
  }
};

export const SessionControls = () => {
  const finishSession = useSetRecoilState(sessionStateState);
  const sessionState = useRecoilValue(sessionStateState);
  const {
    sessionId,
    chat: { messages },
  } = useRecoilValue(sessionAtom);
  const setChat = useSetRecoilState(chatState);

  const isDisabled = useMemo(
    () =>
      sessionState !== SessionState.WAITING_USER_INPUT || messages.length < 2,
    [sessionState, messages.length],
  );

  const reviewSession = useCallback(async () => {
    if (!sessionId) {
      throw new Error("Missing session Id");
    }

    await startReviewFn(sessionId, setChat, finishSession);
  }, [finishSession, setChat, sessionId]);

  return (
    <div className="session-controls">
      <button onClick={reviewSession} disabled={isDisabled}>
        End Session
      </button>
    </div>
  );
};
