import React, { useEffect, useState } from "react";

export const Loader = ({
  text,
  smallText,
  estimatedTime,
}: {
  text?: string;
  smallText?: string;
  estimatedTime?: number;
}) => {
  const [progress, setProgress] = useState("0");

  const loader = estimatedTime && (
    <div className="progress-bar flex">
      <div
        className="inner"
        style={{ width: progress, transitionDuration: `${estimatedTime}s` }}
      ></div>
    </div>
  );

  useEffect(() => {
    if (estimatedTime) {
      setTimeout(() => setProgress("100%"), 100);
    }
  }, [estimatedTime, setProgress]);

  return (
    <div className="loader">
      <div>{text ?? "Loading"}</div>
      <img src="/art/art2.png" alt="Rehearse" />
      <div className="small-text">{smallText ?? ""}</div>
      {loader}
    </div>
  );
};
