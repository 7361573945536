import React, { useCallback, useMemo } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import {
  addMessagesFromUser,
  chatState,
  sessionAtom,
  sessionStateState,
} from "../../atom/sessionAtom";
import { SessionState } from "../../model/session";
import classNames from "classnames";
import { speechToText } from "../../util/SpeechToText";
import { messagesApi } from "../../api/messagesApi";

export const SpeakingButton = () => {
  const { sessionId } = useRecoilValue(sessionAtom);
  const [sessionState, setSessionState] = useRecoilState(sessionStateState);
  const [{ userInput }, setChat] = useRecoilState(chatState);
  const setMessagesFromUser = useSetRecoilState(addMessagesFromUser);

  const handleNewMessageFromUser = useCallback(async () => {
    if (!sessionId) {
      return;
    }

    setMessagesFromUser(userInput);
    setChat((chat) => ({ ...chat, userInput: "", isLoading: true }));
    const message = await messagesApi.send(sessionId, userInput);
    // @ts-ignore
    setChat((chat) => ({
      ...chat,
      messages: [...chat.messages, message],
      isLoading: false,
    }));
    setSessionState(SessionState.COMPUTER_SPEAK);
  }, [userInput, setMessagesFromUser, setSessionState, setChat, sessionId]);

  const speakingButton = useMemo(() => {
    let isEnabled = true;
    let text = "Start";
    let additionalClassName = "";
    let onClick: () => void;

    switch (sessionState) {
      case SessionState.WAITING_USER_INPUT:
        isEnabled = true;
        text = "Speak";
        onClick = () => {
          speechToText.start();
          setSessionState(SessionState.USER_RECORDING);
        };
        break;
      case SessionState.USER_REVIEW_MESSAGE:
        isEnabled = userInput.length !== 0;
        onClick = () => {
          setSessionState(SessionState.SEND_USER_MESSAGE);
          handleNewMessageFromUser();
        };
        text = "Send";
        additionalClassName = "send";
        break;
      case SessionState.USER_RECORDING:
        text = "End";
        isEnabled = true;
        additionalClassName = "recording-animation";
        onClick = () => {
          setTimeout(async () => {
            const newUserInput = await speechToText.stop();
            setChat((chat) => ({ ...chat, userInput: newUserInput }));
            setSessionState(SessionState.USER_REVIEW_MESSAGE);
          }, 500);
        };
        break;
      case SessionState.COMPUTER_SPEAK:
      case SessionState.SEND_USER_MESSAGE:
        isEnabled = false;
        text = "Loading";
        break;
    }

    return (
      <button
        className={classNames("speaking-button", additionalClassName)}
        disabled={!isEnabled}
        onClick={async () => {
          if (onClick) {
            await onClick();
          }
        }}
      >
        {text}
      </button>
    );
  }, [
    sessionState,
    setSessionState,
    userInput,
    setChat,
    handleNewMessageFromUser,
  ]);

  return speakingButton;
};
