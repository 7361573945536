import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { SessionState, WebClientSettings } from "../../model/session";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { clientState, sessionAtom } from "../../atom/sessionAtom";
import { clientSettingsApi } from "../../api/clientSettingsApi";
import { ClientSettings } from "../../model/fydelity";
import classNames from "classnames";
import { Button } from "../theme/Button";

const isNumeric = (str: string | number) => {
  if (typeof str != "string") return false;
  return !isNaN(str as any) && !isNaN(parseFloat(str));
};

const isEmpty = (val: any) => {
  if (val === 0) {
    return false;
  }

  return !val;
};

export const isValidClientSettings = (client: ClientSettings) =>
  !(
    isEmpty(client.name) ||
    isEmpty(client.age) ||
    isEmpty(client.gender) ||
    isEmpty(client.race) ||
    isEmpty(client.personality) ||
    isEmpty(client.diagnostic) ||
    !isNumeric(client.age)
  );

export const ClientSettingsForm = () => {
  const setSession = useSetRecoilState(sessionAtom);
  const clientAtomValue = useRecoilValue(clientState);
  const [client, setClient] = useState<WebClientSettings>(clientAtomValue);
  const [allClients, setAllClients] = useState<WebClientSettings[]>([]);
  const buttonRef = useRef<any>();

  useEffect(() => {
    const getClients = async () => {
      const allClients = await clientSettingsApi.getAllClients();
      setAllClients(allClients);
    };

    getClients();
  }, [setAllClients]);

  useEffect(() => {
    const isDisabled = !isValidClientSettings(client);
    if (isDisabled) {
      buttonRef.current?.setAttribute("disabled", "true");
    } else {
      buttonRef.current?.removeAttribute("disabled");
    }
  }, [buttonRef, client]);

  const handleUpdateClient = useCallback(
    (newClient: WebClientSettings) => {
      setSession((s) => ({
        ...s,
        client: newClient,
      }));
      setClient(newClient);
      clientSettingsApi.select(newClient.id ?? "");
    },
    [setSession],
  );

  const clientsList = useMemo(() => {
    return allClients.map((currentClient) => {
      return (
        <div
          key={currentClient.name}
          className={classNames("select-client", {
            selected: currentClient.id === client.id,
          })}
          onClick={() => handleUpdateClient(currentClient)}
        >
          {currentClient.name}, {currentClient.age} years old
        </div>
      );
    });
  }, [allClients, client, handleUpdateClient]);

  return (
    <div className="new-session-form flex">
      <div className="clients-list">
        <h2>Preset List</h2>
        <div>{clientsList}</div>
      </div>
      <div>
        <h2>Patient - {client.name}</h2>
        <p>Set a pre defined patient.</p>
        <div className="flex row">
          <div className="field">
            <label>Name *</label>
            <input value={client.name} disabled />
          </div>
          <div className="field">
            <label>Age *</label>
            <input value={client.age} disabled />
          </div>
        </div>
        <div className="flex row">
          <div className="field">
            <label>Race *</label>
            <input value={client.race} disabled />
          </div>
          <div className="field">
            <label>Gender *</label>
            <select value={client.gender} disabled>
              <option value=""></option>
              <option value="male">Male</option>
              <option value="female">Female</option>
              <option value="non-binary">non-binary</option>
            </select>
          </div>
        </div>
        <div className="field">
          <label>Personality *</label>
          <textarea value={client.personality} disabled></textarea>
        </div>
        <div className="field">
          <label>Diagnostic *</label>
          <textarea value={client.diagnostic} disabled></textarea>
        </div>
        <div>
          <Button
            onClick={() =>
              setSession((s) => ({
                ...s,
                state: SessionState.OBJECTIVES,
              }))
            }
          >
            Select
          </Button>
        </div>
      </div>
    </div>
  );
};
