import { apiInstance } from "./baseApi";
import { ClientSettings } from "../model/fydelity";
import { WebClientSettings } from "../model/session";

class ClientSettingsApi {
  async getCurrentClient() {
    try {
      const client = await apiInstance.instance
        .get<ClientSettings>("client")
        .then((r) => r.data);
      if (!client) {
        throw new Error("Missing client");
      }

      return client;
    } catch (e) {
      throw new Error(e?.toString());
    }
  }

  async getAllClients() {
    try {
      const allClients = await apiInstance.instance
        .get<WebClientSettings[]>("client/all")
        .then((r) => r.data);
      if (!allClients) {
        throw new Error("Missing client");
      }

      return allClients;
    } catch (e) {
      throw new Error(e?.toString());
    }
  }

  select(clientId: string) {
    apiInstance.instance.get(`client/select/${clientId}`);
  }
}

export const clientSettingsApi = new ClientSettingsApi();
