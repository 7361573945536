import React, { useCallback, useEffect, useRef, useState } from "react";
import "./style.scss";
import "./homepageShared.scss";
import { useNavigate } from "react-router-dom";
import { useExitIntentModal } from "./useExitIntentModal";
import { Button, ButtonColor } from "../theme/Button";
import { ChatView } from "../session/Chat";
import { Role } from "../../model/fydelity";

const exampleText =
  "Fydelity frees supervisor's time by streamlining session evaluation\n" +
  "            using the latest AI models while providing detailed guidelines and\n" +
  "            feedback to trainees to improve patient outcomes.";
const exampleTextLength = exampleText.length;

const Example = () => {
  const [text, setText] = useState("");
  const navigate = useNavigate();

  const createInterval = useCallback(
    () =>
      setInterval(() => {
        const currentTextLength = text.length;
        const textEnd =
          currentTextLength === exampleTextLength ? 0 : currentTextLength + 1;
        const subText = exampleText.slice(0, textEnd);
        setText(subText);
      }, 40),
    [text, setText],
  );

  useEffect(() => {
    let interval = createInterval();

    if (exampleTextLength === text.length) {
      clearInterval(interval);
      setTimeout(() => {
        interval = createInterval();
      }, 3000);
    }

    return () => clearInterval(interval);
  }, [text, setText, createInterval]);

  return (
    <div className="example">
      <div className="box no-max-width-mobile">{text}</div>
      <div>
        <Button color={ButtonColor.DARK} onClick={() => navigate("/demo")}>
          Start practice
        </Button>
      </div>
    </div>
  );
};

export const Homepage = () => {
  const exitIntent = useExitIntentModal();
  const navigate = useNavigate();
  const bannerRef = useRef<HTMLDivElement>(null);

  return (
    <div className="homepage" id="homepage">
      {exitIntent}
      <div className="homepage-inner">
        <div className="flex max-width no-max-width-mobile no-flex-mobile">
          <div className="no-max-width-mobile">
            <h1 className="no-max-width-mobile">
              A New way to practice
              <br /> Evidence Based Therapy
            </h1>
            <div className="description no-max-width-mobile">
              Our new <span className="action">AI powered</span> platform allows
              you to learn, rehearse, and deliver more effective evidence based
              therapy <span className="">(EBTs)</span> like{" "}
              <span className="action">CBT</span> and more.
            </div>
          </div>
          <img
            src="/art/art5.png"
            alt="Learn"
            width="500"
            className="mobile-hide"
          />
        </div>

        <div className="info max-width no-max-width-mobile">
          <h2>Elevate Your Practice with Fydelity</h2>
          <div className="flex items-list">
            <div className="item">
              <h3>Customized Virtual Patients</h3>
              <img src="/art/art12-1.png" alt="Learn" />
              <div className="text">
                Dive into a diverse pool of virtual scenarios that challenge and
                refine your therapeutic skills ahead of a live session
              </div>
            </div>
            <div className="item">
              <h3>Real-Time Feedback</h3>
              <img src="/art/art11-1.png" alt="Rehearse" />
              <div className="text">
                Benefit from AI-generated insights that match supervisor-level
                evaluation, enhancing your therapeutic interventions
              </div>
            </div>
            <div className="item">
              <h3>Progress Tracking</h3>
              <img src="/art/art14-1.png" alt="Rehearse" />
              <div className="text">
                Visualize skill and patient growth with detailed analytics,
                focusing on areas of strength and opportunities for further
                development and patient progress
              </div>
            </div>
          </div>
        </div>

        <div className="example-eval max-width">
          <h2>Real-Time Feedback</h2>
          <div className="flex banner no-flex-mobile">
            <div>
              Access personalized evaluations and feedback, unlocking actionable
              insights that enhance therapeutic skills and ensure the highest
              standards of care.
              <Button
                classes="button"
                onClick={() => navigate("/example-eval")}
                color={ButtonColor.DARK}
              >
                Find more
              </Button>
            </div>
            <img src="/art/art16-1.png" alt="Rehearse" />
          </div>
        </div>

        <div className="banner max-width" ref={bannerRef}>
          <h2>Practice Through Conversation</h2>
          <div className="banner-container flex no-flex-mobile">
            <div className="content">
              <div>
                Fydelity’s customizable virtual patients allow you to practice
                specific modalities across populations (age, gender, background
                and more). While our classroom tested evaluation model gives
                detailed feedback and rates your empathy, impact and general
                skill
              </div>
              <Button
                color={ButtonColor.DARK}
                onClick={() => navigate("/demo")}
              >
                Start practice
              </Button>
            </div>
            <div className="chat-container">
              <ChatView
                messages={[
                  {
                    from: Role.USER,
                    date: new Date(),
                    text: "I managed to stay focused despite distractions. It felt like a real step forward.",
                  },
                  {
                    from: Role.COMPUTER,
                    date: new Date(),
                    text: "That sounds like a significant achievement. Can you tell me more?",
                  },
                  {
                    from: Role.USER,
                    date: new Date(),
                    text: "I remembered our discussions about coping strategies and applied them!",
                  },
                  {
                    from: Role.COMPUTER,
                    date: new Date(),
                    text: "Excellent use of the techniques we discussed.",
                  },
                ]}
                isLoading={false}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="maximize max-width">
        <h2>Why Fydelity and why now</h2>
        <img src="/art/art7.png" alt="Rehearse" className="mobile-hide" />
        <div className="maximize-internal">
          <ol className="content">
            <li>
              Fydelity empowers mental healthcare providers to achieve
              excellence in their practice through advanced AI technology.
            </li>
            <li>
              By simulating real-world interactions and providing instant,
              actionable feedback, Fydelity accelerates the learning curve,
              ensuring that providers are well-equipped to deliver high-fidelity
              mental healthcare.
            </li>
            <li>
              {" "}
              Join us and transform your approach to therapy, making a lasting
              impact on the lives of those you serve.
            </li>
          </ol>
        </div>
      </div>
    </div>
  );
};
