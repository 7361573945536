import axios, { AxiosInstance } from "axios";

class BaseApi {
  axios: AxiosInstance | undefined;

  create(demoId?: string) {
    this.axios = axios.create({
      baseURL: "/api",
      withCredentials: false,
      timeout: 60000,
      headers: {
        "fyd-demo-user-id": demoId,
        "fyd-assistant": "true",
        "Content-Type": "application/json",
      },
    });
    // Ping the server to wake it up.
    this.instance.get("");
  }

  get instance() {
    if (!this.axios) {
      throw new Error("Instance is not initiated");
    }

    return this.axios;
  }
}

export const apiInstance = new BaseApi();
