import React, { useCallback, useEffect, useMemo, useState } from "react";
import { clientSettingsApi } from "../../api/clientSettingsApi";
import { SessionState, WebClientSettings } from "../../model/session";
import {
  DebugPromptRequest,
  PromptKey,
  SessionModel,
} from "../../model/fydelity";
import { debugApi } from "../../api/debugApi";
import { ChatView } from "../session/Chat";
import { Button, ButtonColor } from "../theme/Button";
import { useSetRecoilState } from "recoil";
import { sessionAtom } from "../../atom/sessionAtom";
import { useNavigate } from "react-router-dom";

interface PromptTesterDebugProps {
  promptKey: PromptKey | null;
}

export const PromptTesterDebug = ({ promptKey }: PromptTesterDebugProps) => {
  const [allClients, setAllClients] = useState<WebClientSettings[]>([]);
  const [selectedClientId, setSelectedClientId] = useState<string>("");
  const [selectedSessionId, setSelectedSessionId] = useState<string>("");
  const [allSessions, setAllSessions] = useState<SessionModel[]>([]);
  const [response, setResponse] = useState("");
  const [generatedPrompt, setGeneratedPrompt] = useState("");
  const setSessionAtom = useSetRecoilState(sessionAtom);
  const navigate = useNavigate();
  const [session, setSession] = useState<SessionModel | null>(null);

  useEffect(() => {
    const getClients = async () => {
      const allClients = await clientSettingsApi.getAllClients();
      setAllClients(allClients);

      const allSessions = await debugApi.getAllSessions();
      setAllSessions(allSessions);
    };

    getClients();
  }, [setAllClients, setAllSessions]);

  useEffect(() => {
    const fetch = async () => {
      const session = await debugApi.getSession(selectedSessionId);
      setSession(session);
    };

    if (selectedSessionId) {
      fetch();
    }
  }, [selectedSessionId, setSession]);

  const clientsList = useMemo(() => {
    const options = allClients.map((currentClient) => {
      return (
        <option key={currentClient.id} value={currentClient.id}>
          {currentClient.name}, {currentClient.age} years old
        </option>
      );
    });

    return (
      <select
        value={selectedClientId}
        onChange={(e) => setSelectedClientId(e.target.value)}
      >
        <option value=""></option>
        {options}
      </select>
    );
  }, [allClients, setSelectedClientId, selectedClientId]);

  const sessionsList = useMemo(() => {
    const options = allSessions.map((currentSession) => {
      const [year, month, day, hour, minutes] =
        currentSession.createdDate as unknown as number[];
      const date = new Date(year, month, day);
      date.setHours(hour);
      date.setMinutes(minutes);
      return (
        <option key={currentSession.sessionId} value={currentSession.sessionId}>
          {date.toDateString()} @ {hour}:{minutes}
        </option>
      );
    });

    return (
      <select
        value={selectedSessionId}
        onChange={(e) => setSelectedSessionId(e.target.value)}
      >
        <option value=""></option>
        {options}
      </select>
    );
  }, [allSessions, setSelectedSessionId, selectedSessionId]);

  const client = useMemo(
    () => allClients.find((client) => client.id === selectedClientId),
    [allClients, selectedClientId],
  );

  const loadSession = () => {
    if (!session || !client) {
      return;
    }
    setSessionAtom({
      state: SessionState.WAITING_USER_INPUT,
      chat: {
        isLoading: false,
        messages: session.chat,
        userInput: "",
        feedback: [],
        isDebugChat: false,
        isError: false,
      },
      sessionId: session.sessionId,
      isActive: true,
      client: client,
    });

    navigate("/session");
  };

  const handleSubmit = useCallback(async () => {
    if (!promptKey) {
      return;
    }

    setGeneratedPrompt("");
    setResponse("Loading..... Might take a bit");
    const debugPromptRequest: DebugPromptRequest = {
      sessionId: selectedSessionId,
      clientSettingsId: selectedClientId,
      promptKey: promptKey.toString(),
    };
    try {
      const { response, prompt } =
        await debugApi.testPrompt(debugPromptRequest);
      setResponse(response);
      setGeneratedPrompt(prompt);
    } catch (e) {
      setResponse("error!");
    }
  }, [
    selectedSessionId,
    selectedClientId,
    promptKey,
    setResponse,
    setGeneratedPrompt,
  ]);

  return (
    <div className="tester">
      <h2>Tester</h2>
      <div className="flex-col inputs">
        {clientsList}
        {sessionsList}
        <Button
          onClick={handleSubmit}
          color={ButtonColor.DARK}
          disabled={!promptKey || !selectedClientId || !selectedSessionId}
        >
          Test Prompt
        </Button>
        <Button
          onClick={loadSession}
          color={ButtonColor.DARK}
          disabled={!selectedSessionId}
        >
          Load session
        </Button>
      </div>
      {response && (
        <div className="response">
          <h3>Generated Prompt</h3>
          <pre>{generatedPrompt}</pre>
          <h3>Response</h3>
          <pre>{response}</pre>
        </div>
      )}

      {session && <ChatView isLoading={false} messages={session.chat} />}
    </div>
  );
};
