import React from "react";
import "./forPages.scss";
import "./homepageShared.scss";

export const ForStudents = () => {
  return (
    <div className="for-students max-width">
      <h1>For students</h1>
      <div className="quotes">
        <div className="item">
          <div className="content">
            "I'm pursuing my MSc and I've been struggling with getting access to
            my supervisor... I need to strengthen my clinical skills and explore
            different modalities"
          </div>
          <div className="name">Alex</div>
        </div>
      </div>
      <div className="flex">
        <div>
          <h2>Transform Your Clinical Skills with Fydelity</h2>
          <p className="text">
            Discover the cutting-edge of mental healthcare training with
            Fydelity, an AI-powered platform designed to catapult your clinical
            skills into new heights. As a student in the mental health field,
            Fydelity offers you an extraordinary opportunity to practice,
            refine, and master evidence-based therapies in a supportive, virtual
            environment.
          </p>
        </div>
        <img
          src="/art/art9.png"
          alt="Learn"
          width="500"
          className="mobile-hide image"
        />
      </div>

      <div className="info max-width no-max-width-mobile">
        <h2>Features Designed for Your Success</h2>
        <div className="flex items-list">
          <div className="item">
            <h3>Realistic Virtual Patients</h3>
            <img src="/art/art17-1.png" alt="Learn" />
            <div className="text">
              Engage with a diverse array of virtual patients, each presenting
              unique scenarios that challenge you to apply your knowledge in
              real-world settings
            </div>
          </div>
          <div className="item">
            <h3>Immediate, Constructive Feedback</h3>
            <img src="/art/art18-1.png" alt="Rehearse" />
            <div className="text">
              Receive instant feedback on your therapeutic techniques, enabling
              rapid learning and improvement in your approach to therapy
            </div>
          </div>
          <div className="item">
            <h3>Track Your Progress</h3>
            <img src="/art/art19-1.png" alt="Rehearse" />
            <div className="text">
              Monitor your development through detailed analytics, identifying
              strengths and pinpointing areas for growth to ensure continuous
              advancement in your skills
            </div>
          </div>
        </div>
      </div>

      <div className="why max-width no-max-width-mobile">
        <h2>Why for students</h2>
        <div className="why-inner content">
          Fydelity is your partner in achieving clinical excellence. Our
          platform is more than just a tool; it's a bridge to your future
          career, enhancing your ability to deliver high-quality, compassionate
          care. With Fydelity, you gain not only practical experience but also
          confidence in your skills, preparing you for a successful transition
          from academic learning to professional practice.
        </div>
      </div>
    </div>
  );
};
