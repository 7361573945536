import React, { useEffect, useMemo, useState } from "react";
import "./index.scss";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useRecoilValue, useResetRecoilState } from "recoil";
import { authAtom } from "../../atom/authAtom";
import classNames from "classnames";
import { Tooltip } from "react-tooltip";
import { FaBars } from "react-icons/fa6";
import { useDoAuth } from "../auth/useDoAuth";
import { Button, ButtonColor } from "../theme/Button";

export const Header = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const resetAuth = useResetRecoilState(authAtom);
  const [isPageScrolled, setIsPageScrolled] = useState(false);
  const { isAdmin, isAuth, isLoadingAuth, demoId } = useRecoilValue(authAtom);
  const { doAuth } = useDoAuth();

  useEffect(() => {
    const memoryDemoId = localStorage.getItem("demoId");
    if (!isAuth && memoryDemoId) {
      doAuth(memoryDemoId);
    }
  }, [isAuth, doAuth]);

  const isApp = useMemo(
    () => pathname.startsWith("/session") || pathname.startsWith("/debug"),
    [pathname],
  );
  const isHomepage = useMemo(() => pathname === "/", [pathname]);

  useEffect(() => {
    if (isApp) {
      setIsPageScrolled(true);
      return;
    }

    document.addEventListener(
      "scroll",
      (event) => {
        // @ts-ignore
        if (!event?.target?.classList?.contains("outlet")) {
          return;
        }
        // @ts-ignore
        setIsPageScrolled(event?.target.scrollTop > 5);
      },
      true,
    );
  }, [isApp, isHomepage, setIsPageScrolled]);

  const handleLogout = () => {
    resetAuth();
    localStorage.removeItem("demoId");
    navigate("/");
  };

  const demoButtonPath = isAuth ? "session" : "demo";

  const homepageMenuItems = (
    <>
      <Link className="item hide-no-mobile" to="contact">
        Contact
      </Link>
      <Link className="item" to="for-students">
        For Students
      </Link>
      <Link className="item" to="for-institutions">
        For Institutions
      </Link>
      <Link className="item" to="for-providers">
        For Providers
      </Link>
    </>
  );

  const homepageLinks = (
    <>
      <div className="mobile-hide">{homepageMenuItems}</div>
      <div className="mobile-only">
        <FaBars
          className="mobile-menu"
          data-tooltip-id="mobile-menu-tooltip"
        ></FaBars>
      </div>
    </>
  );

  const homepageMenu = (
    <div className="buttons-menu flex">
      {isAuth ? null : (
        <Link className="item contact mobile-hide" to="contact">
          Contact
        </Link>
      )}

      {isAuth ? (
        <Link
          to={demoButtonPath}
          className="item logout"
          data-tooltip-id="logout-tooltip"
        >
          {demoId?.charAt(0)}
        </Link>
      ) : (
        <Link className="item action" to={demoButtonPath}>
          Demo <span className="mobile-hide">a Therapy Session</span>
        </Link>
      )}
    </div>
  );

  const showDebugMenu = !isApp && isAuth && isAdmin;

  const appLinks = (
    <>
      <Link to="session" className="item session">
        Session
      </Link>
      <Link className="item" to="contact">
        Contact
      </Link>
      {showDebugMenu && (
        <Link to="debug/prompts" className="item">
          Debug/Prompts
        </Link>
      )}
      {showDebugMenu && (
        <Link to="debug/chat" className="item">
          Debug/Chat
        </Link>
      )}
      {showDebugMenu && (
        <Link to="debug/demoId" className="item">
          Debug/DemoId
        </Link>
      )}
    </>
  );

  return (
    <div
      className={classNames("header", {
        homepage: !isApp,
        app: isApp,
        "float-header": isPageScrolled,
      })}
    >
      <div className="header-container flex">
        <Tooltip
          id="logout-tooltip"
          className="logout-tooltip"
          clickable={true}
        >
          <div>Demo ID / {demoId}</div>
          <br />
          <Button color={ButtonColor.TRANSPARENT} onClick={handleLogout}>
            Logout
          </Button>
        </Tooltip>
        <Tooltip
          id="mobile-menu-tooltip"
          className="mobile-menu-tooltip"
          clickable={true}
        >
          {homepageMenuItems}
        </Tooltip>
        <div className="logo flex">
          <Link to="/" className="flex">
            <img src="/logo.svg" className="logo" alt="header fydelity" />
          </Link>
        </div>
        <div className={classNames("menu flex", { loading: isLoadingAuth })}>
          {isApp ? appLinks : homepageLinks}
          <hr />
          {homepageMenu}
        </div>
      </div>
    </div>
  );
};
