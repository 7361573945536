import React, { useCallback, useEffect, useMemo, useRef } from "react";
import { SessionState } from "../../model/session";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { chatState, sessionStateState } from "../../atom/sessionAtom";
import "./recordingAnimation.css";

export const UserTextInput = () => {
  const setChat = useSetRecoilState(chatState);
  const sessionState = useRecoilValue(sessionStateState);
  const { userInput } = useRecoilValue(chatState);
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const handleUserInputChange = useCallback(
    (userInput: string) => {
      setChat((chat) => ({ ...chat, userInput }));
    },
    [setChat],
  );

  const isEnabled = useMemo(
    () => sessionState === SessionState.USER_REVIEW_MESSAGE,
    [sessionState],
  );

  useEffect(() => {
    textareaRef.current?.focus();
  }, [isEnabled, textareaRef]);

  const textarea = useMemo(() => {
    return (
      <textarea
        ref={textareaRef}
        disabled={!isEnabled}
        onChange={(e) => handleUserInputChange(e.target.value)}
        value={userInput}
        placeholder={
          isEnabled ? "No audio is captured, type here" : "Start recording"
        }
        className="draft-message"
      ></textarea>
    );
  }, [handleUserInputChange, userInput, isEnabled]);

  return textarea;
};
