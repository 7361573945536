import React, { useEffect, useState } from "react";
import "./style.css";
import { useRecoilValue } from "recoil";
import { authAtom } from "../../atom/authAtom";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Loader } from "../session/Loader";
import { useDoAuth } from "./useDoAuth";

export const Auth = () => {
  const { isAuth, isLoadingAuth } = useRecoilValue(authAtom);
  const [demoId, setDemoId] = useState<string>("");
  const navigate = useNavigate();
  const { search } = useLocation();
  const { isError, doAuth } = useDoAuth();

  const memoryDemoId = localStorage.getItem("demoId");
  useEffect(() => {
    if (isAuth) {
      const redirect = new URLSearchParams(search).get("redirect");
      if (redirect) {
        navigate(redirect);
      } else {
        navigate("/session");
      }
    } else if (memoryDemoId) {
      doAuth(memoryDemoId);
    }
  }, [isAuth, doAuth, navigate, memoryDemoId, search]);

  if (isLoadingAuth || memoryDemoId) {
    return <Loader />;
  }

  return (
    <div className="auth">
      <h2>Demo Login</h2>
      <p>
        Our Evidence Based Therapy tools are available for a small group of
        trusted partners. To join us please{" "}
        <Link to="/contact">contact us</Link>.
      </p>
      <form className="form">
        {isError && <div className="error">Demo ID does not exist</div>}
        <input
          placeholder="Demo ID"
          onChange={(e) => setDemoId(e.target.value)}
          value={demoId}
        />
        <button onClick={() => doAuth(demoId)}>Start Demo</button>
      </form>
    </div>
  );
};
