import { useEffect, useState } from "react";
import { useRecoilValue, useResetRecoilState } from "recoil";
import { sessionAtom } from "../../atom/sessionAtom";
import { useLocation } from "react-router-dom";
import { SessionState } from "../../model/session";
import Modal from "react-modal";
import { Button, ButtonColor } from "../theme/Button";

const customStyles = {
  content: {
    top: "30%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    border: "unset",
    "box-shadow": "0 0 11px 1px rgb(0 0 0 / 20%)",
    "border-radius": "16px",
  },
};

export const useResetSessionOnExit = () => {
  const { isActive, state } = useRecoilValue(sessionAtom);
  const resetSession = useResetRecoilState(sessionAtom);
  const { pathname } = useLocation();
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (!pathname.startsWith("/session") && isActive) {
      if (state > SessionState.OBJECTIVES) {
        setShowModal(true);
      } else {
        resetSession();
      }
    }
  }, [pathname, resetSession, isActive, state]);

  const reset = () => {
    setShowModal(false);
    resetSession();
  };

  return (
    <Modal isOpen={showModal} style={customStyles}>
      <div className="reset-modal">
        <h2>Reset Session?</h2>
        <div className="buttons flex">
          <Button onClick={() => reset()} color={ButtonColor.BRAND}>
            Reset
          </Button>
          <Button onClick={() => setShowModal(false)} color={ButtonColor.DARK}>
            No
          </Button>
        </div>
      </div>
    </Modal>
  );
};
