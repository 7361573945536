import React from "react";
import { EvalCriteria, PromptKey } from "../../model/fydelity";
import { EvalView } from "../session/FeedbackView";
import "../session/evaluation.scss";

export const fakeEvalData: { [key in PromptKey]?: EvalCriteria } = {
  [PromptKey.FEEDBACK_RUBRIC_1]: {
    promptKey: PromptKey.FEEDBACK_RUBRIC_1,
    hasError: false,
    score: 3,
    summary:
      "The therapist in this session demonstrated a high level of skill in adhering to the\n" +
      "CTRS-R criteria for setting an agenda. All required elements were present, including a\n" +
      "transition from the previous session, identification of significant events, review of the\n" +
      "Action Plan, a mood check, and setting specific goals for the current session. Additionally,\n" +
      "the therapist made sure to address and prioritize all items important to the client and\n" +
      "maintained the agenda throughout the session, justifying a score of 3.",
    reasons: [
      "The therapist provided a smooth transition from the previous session by asking about\n" +
        "the client's current stress level and relating it to previous discussions on depression and\n" +
        "anxiety.",
      "Significant events since the previous session were identified by the therapist asking the\n" +
        "client about positive occurrences in the past week, which the client responded to with\n" +
        "examples of a family outing and a work-related win.",
    ],
  },
  [PromptKey.FEEDBACK_RUBRIC_2]: {
    promptKey: PromptKey.FEEDBACK_RUBRIC_2,
    hasError: false,
    score: 2,
    summary:
      "The therapist in this session demonstrated a strong adherence to the CTRS-R\n" +
      "rubric. They actively engaged the client in the therapeutic process, ensuring that the\n" +
      "client's reactions, understanding, and agreement with the treatment plan were clear. The\n" +
      "therapist responded appropriately to the client's feedback and incorporated it into the\n" +
      "session, which is evident in the development of an Action Plan that aligns with the client's\n" +
      "goals. The therapist's approach to requesting feedback was fluid and consistent\n" +
      "throughout the session, including during agenda setting and transitions. Overall, the\n" +
      "therapist completed all the required items and went beyond by fluidly integrating\n" +
      "feedback throughout the session, warranting a score of 3.",
    reasons: [
      "The therapist actively sought the client's feedback throughout the session, ensuring that\n" +
        "the client's perspective was considered in the treatment plan.",
      "The therapist used the client's feedback to develop an Action Plan, which included\n" +
        "talking to the husband about expectations and filling out forms to facilitate this\n" +
        "discussion.",
    ],
  },
  [PromptKey.FEEDBACK_RUBRIC_3]: {
    promptKey: PromptKey.FEEDBACK_RUBRIC_3,
    hasError: false,
    score: 1,
    summary:
      "The therapist in this session has demonstrated a high level of understanding of\n" +
      "the client's issues, consistently reflecting and summarizing the client's statements,\n" +
      "acknowledging the client's emotional state, and linking it to the conceptualization of her\n" +
      "problems. The therapist's approach was empathetic and supportive, facilitating a positive\n" +
      "therapeutic environment. The therapist's understanding was not only conveyed through\n" +
      "words but also through the tone and manner of engagement, which suggests a score of 3\n" +
      "on the CTRS-R Understanding Criteria.",
    reasons: [
      "The therapist's demonstration of understanding the client’s emotional state was\n" +
        "accomplished by a combination of words, expressions, gestures, tone, and body\n" +
        "language throughout the session, which is implied through the client's positive feedback\n" +
        "and the therapist's empathetic responses.",
      "The therapist discussed the client’s emotional state within the context of the\n" +
        "conceptualization, linking the client's behaviors and assumptions to her feelings of\n" +
        "loneliness and fear of relationships.",
    ],
  },
};

export const ExampleEval = () => {
  const feedbacks = Object.values(fakeEvalData);
  return (
    <div className="feedback feedback-example">
      <h1>Supervisor Level Feedback - Instantly</h1>
      <div className="promo flex no-flex-mobile">
        <p>
          Fydelity is the only platform capable of providing both quantitative
          and qualitative holistic feedback. For any session video, audio or
          transcript, recieve feedback related to skill, empathy, pacing and
          more. Below is a partial evaluation report based on Cognitive Therapy
          Rating Scale (CTRS-R) principles for a 45 minutes client session.
        </p>
        <img src="/art/art10.png" alt="Rehearse" />
      </div>
      <ExampleEvalPage feedbacks={feedbacks} />
    </div>
  );
};

export const ExampleEvalPage = ({
  feedbacks,
}: {
  feedbacks: EvalCriteria[];
}) => (
  <div className="feedback flex">
    {feedbacks.map((v) => (
      <EvalView {...v} />
    ))}{" "}
  </div>
);
