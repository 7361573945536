import { useEffect } from "react";
import { useRecoilValue } from "recoil";
import { authAtom } from "../../atom/authAtom";
import { useLocation, useNavigate } from "react-router-dom";

export const useAuth = (requireAdmin = false, redirect = false) => {
  const { isAuth, isAdmin, availableUsage } = useRecoilValue(authAtom);
  const navigate = useNavigate();
  const { pathname } = useLocation();

  useEffect(() => {
    if (!isAuth || (requireAdmin && !isAdmin)) {
      const redirectUrl = redirect ? `?redirect=${pathname}` : "";
      const url = `/demo${redirectUrl}`;
      navigate(url);
    }

    if (isAuth && availableUsage < 1) {
      navigate("/access");
    }
  }, [
    isAuth,
    navigate,
    isAdmin,
    requireAdmin,
    availableUsage,
    pathname,
    redirect,
  ]);
};
