import { apiInstance } from "./baseApi";
import {
  DebugPromptRequest,
  DebugPromptResponse,
  PromptKey,
  PromptModel,
  SessionModel,
  UpdatePromptRequest,
} from "../model/fydelity";

class DebugApi {
  async updatePrompt(promptKey: PromptKey, prompt: string) {
    await apiInstance.instance
      .post<UpdatePromptRequest>("debug/prompt", { promptKey, prompt })
      .then((r) => r.data);
  }

  async getAllPrompts() {
    return await apiInstance.instance
      .get<Promise<{ [key in PromptKey]: PromptModel }>>("debug/prompt")
      .then((r) => r.data);
  }

  async createMessages(chat: string[]) {
    return apiInstance.instance
      .post<SessionModel>("debug/chat", chat)
      .then((r) => r.data);
  }

  async testPrompt(debugPromptRequest: DebugPromptRequest) {
    return apiInstance.instance
      .post<DebugPromptResponse>("debug/chat/test", debugPromptRequest)
      .then((r) => r.data);
  }

  async getAllSessions() {
    return apiInstance.instance
      .get<SessionModel[]>("debug/chat/all")
      .then((r) => r.data);
  }

  async getSession(sessionId: string) {
    return apiInstance.instance
      .get<SessionModel>(`debug/chat/session/${sessionId}`)
      .then((r) => r.data);
  }

  async createDemoId(name: string, linkedin: string) {
    return apiInstance.instance
      .get<string>(`debug/demo-id?name=${name}&linkedin=${linkedin}`)
      .then((r) => r.data);
  }
}

export const debugApi = new DebugApi();
