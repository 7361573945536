import { apiInstance } from "./baseApi";
import { DemoId } from "../model/fydelity";

class DemoApi {
  async auth(): Promise<DemoId | null> {
    try {
      const response = await apiInstance.instance.get("demo");
      return response.data;
    } catch (e) {
      console.error(e);
      return null;
    }
  }

  async signDisclaimer(email: string): Promise<DemoId | null> {
    try {
      const response = await apiInstance.instance.get(
        `demo/sign-disclaimer?email=${email}`,
      );
      return response.data;
    } catch (e) {
      console.error(e);
      return null;
    }
  }
}

export const demoApi = new DemoApi();
