import React, { useCallback, useMemo, useState } from "react";
import { debugApi } from "../../api/debugApi";
import copy from "copy-to-clipboard";

export const DebugDemoId = () => {
  const [linkedin, setLinkedin] = useState("");
  const [name, setName] = useState("");
  const [demoId, setDemoId] = useState("");

  const handleCreate = useCallback(async () => {
    if (!name) {
      return;
    }
    const demoId = await debugApi.createDemoId(name, linkedin);
    setLinkedin("");
    setDemoId(demoId);
  }, [name, linkedin, setLinkedin, setDemoId]);

  const details = useMemo(() => {
    if (!demoId) {
      return null;
    }

    const [first] = name.split(" ");
    const text = `Hi ${first},\nI am working on a new way to train and evaluate clinicians on Evidence Based Therapies (EBTs), and wanted to connect and chat about your needs (not sales call). We are currently in alpha, and would love to get your thoughts if you want to try joinfydelity.com using demo id "${demoId}" to access the app.\n\nBest,\nDaniel`;

    return (
      <div className="box">
        <pre>{text}</pre>
        <button onClick={() => copy(text)}>Copy</button>
      </div>
    );
  }, [demoId, name]);

  return (
    <div className="debug-demo-id">
      <h1>Demo Id</h1>
      <div>
        <label>Full name</label>
        <input
          placeholder="Name"
          onChange={(e) => setName(e.target.value)}
          value={name}
        />
      </div>
      <div>
        <label>Optional Linkedin link</label>
        <input
          placeholder="Linkedin"
          onChange={(e) => setLinkedin(e.target.value)}
          value={linkedin}
        />
      </div>
      <button onClick={handleCreate}>Generate</button>
      {details}
    </div>
  );
};
