/* tslint:disable */
/* eslint-disable */
// Generated using typescript-generator version 3.2.1263 on 2023-12-18 22:03:19.

export interface DebugPromptRequest {
  sessionId: string;
  clientSettingsId: string;
  promptKey: string;
}

export interface DebugPromptRequestBuilder {}

export interface DebugPromptResponse {
  prompt: string;
  response: string;
}

export interface EvalCriteria {
  promptKey: PromptKey;
  hasError: boolean;
  score: number;
  summary: string;
  reasons: string[];
}

export interface EvalCriteriaBuilder {}

export interface MessageFromUserRequest {
  text: string;
  sessionId: string;
}

export interface NewContactRequest {
  name: string;
  email: string;
  profession: string;
  professionOther: string;
  freeForm: string;
}

export interface UpdatePromptRequest {
  promptKey: PromptKey;
  prompt: string;
}

export interface UpdatePromptRequestBuilder {}

export interface ClientSettings {
  id: string;
  isDefault: boolean;
  name: string;
  gender: string;
  age: string;
  race: string;
  diagnostic: string;
  personality: string;
  default: boolean;
}

export interface ClientSettingsBuilder {}

export interface ContactModel {
  sessionId: string;
  name: string;
  email: string;
  profession: string;
  professionOther: string;
  freeForm: string;
}

export interface ContactModelBuilder {}

export interface DemoId {
  id: string;
  availableUsage: number;
  clientSettingsId: string;
  disclaimerSignDate: Date;
  email: string;
  admin: boolean;
}

export interface DemoIdBuilder {}

export interface DemoIdMetaModel {
  id: string;
  fullName: string;
  linkedin: string;
}

export interface DemoIdMetaModelBuilder {}

export interface PromptModel {
  promptKey: PromptKey;
  prompt: string;
  vars: string[];
  history: string[];
}

export interface PromptModelBuilder {}

export interface SessionModel {
  sessionId: string;
  demoId: string;
  chat: Message[];
  feedbacks: FeedbackRecord[];
  createdDate: Date;
}

export interface FeedbackRecord {
  prompt: string;
  feedback: string;
  timeToGenerate: Duration;
  isSuccess: boolean;
  exceptionMessage: string;
  success: boolean;
}

export interface FeedbackRecordBuilder {}

export interface Message {
  from: Role;
  date: Date;
  text: string;
}

export interface MessageBuilder {}

export interface SessionModelBuilder {}

export interface Duration
  extends TemporalAmount,
    Comparable<Duration>,
    Serializable {}

export interface TemporalAmount {
  units: TemporalUnit[];
}

export interface Serializable {}

export interface TemporalUnit {
  durationEstimated: boolean;
  duration: Duration;
  timeBased: boolean;
  dateBased: boolean;
}

export interface Comparable<T> {}

export enum SessionSkill {
  IAT = "IAT",
  CCD = "CCD",
  EBA = "EBA",
  ET = "ET",
  EER = "EER",
  RP = "RP",
}

export enum PromptKey {
  CHAT_FIRST_MESSAGE = "CHAT_FIRST_MESSAGE",
  PRE_FEEDBACK_SYSTEM_MESSAGE = "PRE_FEEDBACK_SYSTEM_MESSAGE",
  BASE = "BASE",
  CHAT = "CHAT",
  FEEDBACK = "FEEDBACK",
  FEEDBACK_BASE = "FEEDBACK_BASE",
  FEEDBACK_RUBRIC_1 = "FEEDBACK_RUBRIC_1",
  FEEDBACK_RUBRIC_2 = "FEEDBACK_RUBRIC_2",
  FEEDBACK_RUBRIC_3 = "FEEDBACK_RUBRIC_3",
  FEEDBACK_RUBRIC_4 = "FEEDBACK_RUBRIC_4",
  FEEDBACK_RUBRIC_5 = "FEEDBACK_RUBRIC_5",
  FEEDBACK_RUBRIC_6 = "FEEDBACK_RUBRIC_6",
  FEEDBACK_RUBRIC_7 = "FEEDBACK_RUBRIC_7",
  FEEDBACK_RUBRIC_8 = "FEEDBACK_RUBRIC_8",
  FEEDBACK_RUBRIC_9 = "FEEDBACK_RUBRIC_9",
  FEEDBACK_RUBRIC_10 = "FEEDBACK_RUBRIC_10",
  FEEDBACK_RUBRIC_11 = "FEEDBACK_RUBRIC_11",
}

export enum Role {
  COMPUTER = "COMPUTER",
  USER = "USER",
}
