import React, { useState } from "react";
import { demoApi } from "../../api/demoApi";
import { useSetRecoilState } from "recoil";
import { authAtom } from "../../atom/authAtom";
import { validateEmail } from "../contact/Contact";
import { gEvent } from "../../util/gtag";

export const DisclaimerView = () => {
  const setAuth = useSetRecoilState(authAtom);
  const [email, setEmail] = useState("");

  const handleSign = async () => {
    gEvent("sign_disclaimer");
    await demoApi.signDisclaimer(email);
    setAuth((old) => ({ ...old, dismalerSigned: true }));
  };

  return (
    <div className="disclaimer">
      <h1>Disclaimer</h1>
      <div className="content">
        <p>
          Fydelity is an innovative tool designed exclusively for research and
          educational purposes. It is not intended to replace professional
          medical or psychological advice, diagnosis, or treatment. Using this
          system with real patients should be done with care..
        </p>
        <p>
          1. No Guarantee of Efficacy: Fydelity is currently in its experimental
          phase and may not provide accurate or effective therapeutic support.
          We make no guarantees regarding specific outcomes, results, or
          improvements in mental health or well-being resulting from the use of
          this system.
        </p>
        <p>
          2. Not a Replacement for Professional Care: This system does not
          substitute for the expertise of licensed clinicians, therapists, or
          medical professionals. Users must seek guidance from qualified
          professionals for any mental health concerns or therapeutic needs.
        </p>
        <p>
          3. Not for Emergency Situations: In emergency situations or when
          individuals are experiencing severe mental health crises, this system
          should not be used. Contact emergency services or a mental health
          professional immediately.
        </p>
        <p>
          4. Limited Liability: Fydelity and its developers bear no liability
          for any direct or indirect damages, injuries, or negative consequences
          arising from the use of the Fydelity. Users assume full responsibility
          and risk associated with its utilization.
        </p>
        <p>
          5. Ethical Considerations: Users must adhere to ethical guidelines and
          regulations governing the practice of therapy and counseling when
          employing this system for research or educational purposes.
        </p>
        <p>
          6. Informed Consent: When using this system within a research or
          educational context, ensure that participants are fully informed of
          its experimental nature and limitations.
        </p>
        <p>
          7. User Acknowledgment: By using Fydelity, users acknowledge that they
          have read and comprehended this disclaimer. They agree to use the
          system responsibly and in compliance with applicable laws and
          regulations.
        </p>
        <p>
          Please Note: Fydelity at this time is not intended for clinical use
          and should be used exclusively by individuals or organizations
          conducting non-clinical research or educational activities. Any
          deviations from these guidelines may have legal and ethical
          consequences.
        </p>
      </div>
      <div>
        <input placeholder="Email" onChange={(e) => setEmail(e.target.value)} />
        <button onClick={handleSign} disabled={!validateEmail(email)}>
          I agree
        </button>
      </div>
    </div>
  );
};
