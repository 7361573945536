import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { PromptModel } from "../../model/fydelity";
import { Button, ButtonColor } from "../theme/Button";

interface DebugPromptDataProps {
  promptData: PromptModel;
  onPromptChange: (prompt: string) => void;
}

export const DebugPromptData = ({
  promptData,
  onPromptChange,
}: DebugPromptDataProps) => {
  const isUsed = (key: string) => promptData.prompt.includes(key);
  const [promptValue, setPromptValue] = useState(promptData.prompt);

  const vars = (
    <ul>
      {promptData.vars.map((v, i) => (
        <li
          key={i}
          className={classNames({ used: isUsed(`%${v}%`) })}
        >{`%${v}%`}</li>
      ))}
    </ul>
  );
  const textArea = (
    <textarea
      onChange={(e) => setPromptValue(e.target.value)}
      value={promptValue}
    />
  );

  return (
    <div>
      <Button
        color={ButtonColor.DARK}
        onClick={() => onPromptChange(promptValue)}
      >
        Update
      </Button>
      {vars}
      {textArea}
    </div>
  );
};
