import React from "react";
import "./homepageShared.scss";

export const ForProviders = () => {
  return (
    <div className="for-students max-width">
      <h1>For Providers</h1>
      <div className="flex">
        <div>
          <h2>Elevate Your Practice with Fydelity</h2>
          <p className="text">
            Fydelity's AI-driven platform revolutionizes mental healthcare
            practice, offering an unmatched blend of technology and empathy. Our
            platform is crafted to meet the unique needs of mental healthcare
            providers, ensuring that your practice stands at the forefront of
            evidence-based therapies.
          </p>
        </div>
        <img
          src="/art/art7.png"
          alt="Learn"
          width="500"
          className="mobile-hide image"
        />
      </div>

      <div className="info max-width no-max-width-mobile">
        <h2>Features at a Glance</h2>
        <div className="flex items-list">
          <div className="item">
            <h3>Customized Virtual Patients</h3>
            <img src="/art/art17-1.png" alt="Learn" />
            <div className="text">
              Dive into a diverse pool of virtual scenarios that challenge and
              refine your therapeutic skills ahead of a live session
            </div>
          </div>
          <div className="item">
            <h3>Real-Time Feedback</h3>
            <img src="/art/art18-1.png" alt="Rehearse" />
            <div className="text">
              Benefit from AI-generated insights that match supervisor-level
              evaluation, enhancing your therapeutic interventions
            </div>
          </div>
          <div className="item">
            <h3>Progress Tracking</h3>
            <img src="/art/art19-1.png" alt="Rehearse" />
            <div className="text">
              Visualize skill and patient growth with detailed analytics,
              focusing on areas of strength and opportunities for further
              development and patient progress
            </div>
          </div>
        </div>
      </div>

      <div className="why">
        <h2>What do we do well</h2>
        <div className="why-container flex max-width">
          <div className="content">
            Fydelity empowers mental healthcare providers to achieve excellence
            in their practice through advanced AI technology. <br />
            <br /> By simulating real-world interactions and providing instant,
            actionable feedback, Fydelity accelerates the learning curve,
            ensuring that providers are well-equipped to deliver high-fidelity
            mental healthcare. Join us and transform your approach to therapy,
            making a lasting impact on the lives of those you serve.
          </div>
          <img src="/art/art6.png" alt="Rehearse" className="mobile-hide" />
        </div>
      </div>

      <div className="why max-width no-max-width-mobile">
        <h2>Maximize Reimbursement with High-Quality Care</h2>
        <div className="why-inner content">
          Fydelity’s AI-driven platform not only elevates the quality of mental
          healthcare delivery but also positions your practice for higher
          insurance reimbursements and successful participation in value-based
          care programs. By enhancing clinician skills and ensuring
          high-fidelity therapy, Fydelity directly contributes to improved
          patient outcomes. These outcomes are essential in value-based care
          models, where reimbursement is increasingly tied to the quality of
          care and patient satisfaction rather than the quantity of services
          provided.
        </div>
      </div>
    </div>
  );
};
