import { useCallback, useState } from "react";
import { gEvent, setGTag } from "../../util/gtag";
import { apiInstance } from "../../api/baseApi";
import { demoApi } from "../../api/demoApi";
import { DemoId } from "../../model/fydelity";
import { useResetRecoilState, useSetRecoilState } from "recoil";
import { authAtom } from "../../atom/authAtom";
import { sessionAtom } from "../../atom/sessionAtom";

export const useDoAuth = () => {
  const setAuth = useSetRecoilState(authAtom);
  const resetSession = useResetRecoilState(sessionAtom);
  const [isError, setError] = useState(false);

  const handleAuthSuccessResponse = useCallback(
    (demoId: DemoId) => {
      gEvent("auth-success", { demoId: demoId.id });
      const { id, availableUsage, admin, disclaimerSignDate } = demoId;
      setGTag(id);

      setTimeout(() => {
        setAuth((auth) => ({
          ...auth,
          isAuth: true,
          demoId: id,
          isAdmin: admin,
          availableUsage,
          dismalerSigned: !!disclaimerSignDate,
          isLoadingAuth: false,
        }));
        localStorage.setItem("demoId", id);
      }, 500);
    },
    [setAuth],
  );

  const handleAuthFailResponse = useCallback(
    (demoId: string) => {
      gEvent("auth-fail", { demoId });
      setTimeout(() => {
        // The fast loading is jerking on the user.
        setAuth((auth) => ({
          ...auth,
          isAuth: false,
          demoId: undefined,
          isAdmin: false,
          dismalerSigned: false,
          isLoadingAuth: false,
        }));
        localStorage.removeItem("demoId");
        setError(true);
      }, 300);
    },
    [setAuth, setError],
  );

  const doAuth = useCallback(
    async (demoId: string) => {
      gEvent("auth-try", { demoId });
      if (!demoId) {
        return;
      }

      resetSession();
      setAuth((old) => ({ ...old, isLoadingAuth: true }));
      setError(false);
      apiInstance.create(demoId);
      const demoIdModel = await demoApi.auth();
      if (demoIdModel) {
        handleAuthSuccessResponse(demoIdModel);
        return true;
      } else {
        handleAuthFailResponse(demoId);
        return false;
      }
    },
    [
      handleAuthFailResponse,
      handleAuthSuccessResponse,
      setError,
      setAuth,
      resetSession,
    ],
  );

  return { doAuth, isError };
};
