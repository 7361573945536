import React from "react";
import "./style.css";
import { useRecoilState } from "recoil";
import { settingsAtom } from "../../atom/settingsAtom";

export const VoiceSettings = () => {
  const [settings, setSettings] = useRecoilState(settingsAtom);

  const voices = speechSynthesis
    .getVoices()
    .filter((voice) => voice.lang.startsWith("en-"));

  const handleVoiceDropdownChange = (
    e: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    const voice = voices.find((voice) => voice.voiceURI === e.target.value);
    if (voice) {
      setSettings((settings) => ({ ...settings, voice: voice.voiceURI }));
    }
  };

  return (
    <div className="voice-settings">
      <div className="row">
        <div className="key">Voice</div>
        <div className="value">
          <select onChange={handleVoiceDropdownChange} value={settings.voice}>
            {voices.map((v) => (
              <option value={v.voiceURI} key={`${v.voiceURI}-${v.default}`}>
                {v.voiceURI}
              </option>
            ))}
          </select>
        </div>
      </div>
    </div>
  );
};
