import { atom } from "recoil";
import { Auth } from "../model/auth";

export const authAtom = atom<Auth>({
  key: "auth",
  default: {
    demoId: undefined,
    isAuth: false,
    isAdmin: false,
    dismalerSigned: false,
    availableUsage: 0,
    isLoadingAuth: false,
  },
});
