import React, { useEffect, useMemo, useRef } from "react";
import { useRecoilValue } from "recoil";
import { chatState } from "../../atom/sessionAtom";
import { Message, Role } from "../../model/fydelity";
import classNames from "classnames";

interface ChatViewProps {
  messages: Message[];
  isLoading: boolean;
}

export const ChatView = ({ messages, isLoading }: ChatViewProps) => {
  const chatRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const chatEl = chatRef.current;
    if (chatEl && messages.length) {
      chatEl.scrollTo(0, chatEl.scrollHeight);
    }
  });

  const messagesEl = useMemo(() => {
    return messages.map(({ text, from }, index) => {
      const isUser = from === Role.USER;

      return (
        <div className="message-container flex" key={index}>
          <img
            src={isUser ? "/art/doctor-avatar.png" : "/art/avatar.svg"}
            className="avatar"
          />
          <div
            key={index}
            className={classNames("message", isUser ? "user" : "computer")}
          >
            {text}
          </div>
        </div>
      );
    });
  }, [messages]);

  const loadingMessage = useMemo(() => {
    if (!isLoading) {
      return null;
    }

    return (
      <div className="message-container flex">
        <img
          src={
            messages.length % 2 ? "/art/doctor-avatar.png" : "/art/avatar.svg"
          }
          className="avatar"
        />
        <div className="message loading shimmer">
          Typing... <span>|</span>
        </div>
      </div>
    );
  }, [isLoading, messages.length]);

  return (
    <div className="chat" ref={chatRef}>
      {messagesEl}
      {loadingMessage}
    </div>
  );
};

export const Chat = () => {
  const { messages, isLoading } = useRecoilValue(chatState);
  return <ChatView messages={messages} isLoading={isLoading} />;
};
