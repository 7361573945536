import React, { useCallback, useEffect, useMemo } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import {
  chatState,
  clientState,
  sessionAtom,
  sessionStateState,
} from "../../atom/sessionAtom";
import { SessionState } from "../../model/session";
import { ClientSettingsForm } from "./ClientSettingsForm";
import { SessionView } from "./SessionView";
import { FeedbackView } from "./FeedbackView";
import { useAuth } from "../auth/useAuth";
import { clientSettingsApi } from "../../api/clientSettingsApi";
import { authAtom } from "../../atom/authAtom";
import { messagesApi } from "../../api/messagesApi";
import { DisclaimerView } from "./DisclaimerView";
import { Loader } from "./Loader";
import { ObjectivesView } from "./ObjectivesView";
import { gEvent } from "../../util/gtag";

export const SessionController = () => {
  const [sessionState, setSessionState] = useRecoilState(sessionStateState);
  const [client, setClient] = useRecoilState(clientState);
  const setChat = useSetRecoilState(chatState);
  const [{ isActive }, setSession] = useRecoilState(sessionAtom);
  const { isAuth, dismalerSigned } = useRecoilValue(authAtom);
  useAuth();

  useEffect(() => {
    if (!isActive) {
      setSession((old) => ({ ...old, isActive: true }));
    }
  }, [isActive, setSession]);

  const loadClient = useCallback(async () => {
    if (client.isLoaded) {
      return;
    }

    const newClient = await clientSettingsApi.getCurrentClient();
    setClient(() => ({ ...newClient, isLoaded: true }));
  }, [setClient, client.isLoaded]);

  const initiateSession = useCallback(async () => {
    gEvent("session_start");
    loadClient();
    setSessionState(SessionState.LOADING_DATA);
    const messages = await messagesApi.create();
    setChat((chat) => ({ ...chat, messages: messages.chat }));
    setSession((session) => ({ ...session, sessionId: messages.sessionId }));
    setSessionState(SessionState.WAITING_USER_INPUT);
  }, [setSessionState, setSession, setChat, loadClient]);

  const sessionComponent = useMemo(() => {
    if (!isAuth) {
      return null;
    }

    if (!dismalerSigned) {
      gEvent("Disclaimer_viewed");
      return <DisclaimerView />;
    }

    if (sessionState === SessionState.LOADING_DATA) {
      return <Loader />;
    }

    if (sessionState === SessionState.INITIATE_SESSION) {
      initiateSession();
      return <Loader />;
    }

    if (sessionState === SessionState.CLIENT_SETTINGS || !client) {
      return <ClientSettingsForm />;
    }

    if (sessionState === SessionState.OBJECTIVES) {
      loadClient();
      return <ObjectivesView />;
    }

    if (sessionState === SessionState.REVIEW) {
      return <FeedbackView />;
    }

    return <SessionView />;
  }, [
    sessionState,
    initiateSession,
    isAuth,
    dismalerSigned,
    client,
    loadClient,
  ]);

  return sessionComponent;
};
