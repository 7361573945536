export const gEvent = (name: string, params?: any) => {
  // @ts-ignore
  gtag("event", `fy_${name}`, params);
};

export const setGTag = (demoId: string) => {
  // @ts-ignore
  gtag("config", "G-F77C8E99PN", {
    demo_id: demoId,
  });
};
