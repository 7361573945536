import { atom, selector } from "recoil";
import { Chat, defaultSession, SessionState } from "../model/session";
import { ClientSettings, Message, Role } from "../model/fydelity";

export const sessionAtom = atom({
  key: "session",
  default: defaultSession,
  // effects: [sessionSessionLocalStorageEffect()],
});

export const sessionStateState = selector({
  key: "sessionState",
  get: ({ get }) => get(sessionAtom).state,
  set: ({ set, get }, type) => {
    set(sessionAtom, { ...get(sessionAtom), state: type as SessionState });
  },
});

export const clientState = selector({
  key: "client",
  get: ({ get }) => get(sessionAtom).client,
  set: ({ set, get }, client) => {
    // @ts-ignore
    set(sessionAtom, { ...get(sessionAtom), client: client as ClientSettings });
  },
});

export const chatState = selector<Chat>({
  key: "chat",
  get: ({ get }) => get(sessionAtom).chat,
  set: ({ set, get }, newValue) => {
    set(sessionAtom, { ...get(sessionAtom), chat: newValue as Chat });
  },
});

export const addMessagesFromUser = selector<string>({
  key: "addMessageUser",
  get: () => "",
  set: ({ get, set }, text) => {
    const message: Message = {
      from: Role.USER,
      date: new Date(),
      text: text as unknown as string,
    };
    set(chatState, (chat) => ({
      ...chat,
      messages: [...chat.messages, message],
    }));
  },
});

export const computerMessages = selector<Message[]>({
  key: "addMessageComputer",
  get: ({ get }) =>
    get(chatState).messages.filter((msg) => msg.from === Role.COMPUTER),
  set: ({ get, set }, text) => {
    const message: Message = {
      from: Role.COMPUTER,
      date: new Date(),
      text: text as unknown as string,
    };
    set(chatState, (chat) => ({
      ...chat,
      messages: [...chat.messages, message],
    }));
  },
});
