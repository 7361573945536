import React, { useState } from "react";
import { useRecoilValue, useResetRecoilState } from "recoil";
import { chatState } from "../../atom/sessionAtom";
import { debugMessagesStorageKey } from "../../util/persistenceHistoryAtomStorage";
import { useNavigate } from "react-router-dom";
import { debugApi } from "../../api/debugApi";
import { useAuth } from "../auth/useAuth";
import csvtojson from "csvtojson";

export const DebugChat = () => {
  const { isDebugChat } = useRecoilValue(chatState);
  const resetChat = useResetRecoilState(chatState);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  useAuth(true, true);

  const readFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files?.length) {
      return;
    }

    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = async ({ target }) => {
      if (!target?.result) {
        return;
      }
      setLoading(true);
      const csv = await csvtojson().fromString(target.result.toString());
      const chat = csv.map(({ text }) => text);
      await debugApi.createMessages(chat);
      setLoading(false);
      navigate("/debug/prompts");
    };
    reader.readAsText(file);
  };

  const handleReset = () => {
    localStorage.removeItem(debugMessagesStorageKey);
    resetChat();
    navigate("/session");
  };
  if (loading) {
    return <div>Loading, might take 30 seconds.</div>;
  }
  return (
    <div className="box">
      <h1>Debug Chat</h1>
      {isDebugChat ? (
        <button onClick={handleReset}>Reset</button>
      ) : (
        <input
          type="file"
          onChange={readFile}
          accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
        />
      )}
    </div>
  );
};
