import React, { useEffect, useMemo, useState } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { clientState, sessionStateState } from "../../atom/sessionAtom";
import { SessionState } from "../../model/session";
import { authAtom } from "../../atom/authAtom";
import { Button, ButtonColor, ButtonSize } from "../theme/Button";
import { FaAnglesRight, FaPencil } from "react-icons/fa6";
import { SessionSkill } from "../../model/fydelity";
import { SkillSelector } from "./SkillSelector";
import classNames from "classnames";
import { gEvent } from "../../util/gtag";

export const ObjectivesView = () => {
  const setSessionState = useSetRecoilState(sessionStateState);
  const clientSettings = useRecoilValue(clientState);
  const { availableUsage } = useRecoilValue(authAtom);
  const [selectedSkill, setSelectedSkill] = useState<SessionSkill | null>(null);

  const clientSettingsLoad = useMemo(
    () => clientSettings.isLoaded,
    [clientSettings.isLoaded],
  );

  const startSessionView = useMemo(
    () => (
      <div className="start-session">
        <h2>
          CBT / Identifying Automatic Thoughts
          <Button
            onClick={() => setSelectedSkill(null)}
            color={ButtonColor.TRANSPARENT}
            IconRight={FaPencil}
          >
            Change
          </Button>
        </h2>
        <div className="info">
          <h3>Exercise / Thought Records</h3>
          <div className="text">
            You will be practicing the CBT skill of Identifying Automatic
            Thoughts. The goal is to guide clients through the process of
            tracking their automatic thoughts in response to certain triggers,
            identifying the emotions and behaviors that follow.
            <p>
              You are about to start a session with{" "}
              <span className="highlight">{clientSettings.name}</span>. Remember
              they are a{" "}
              <span className="highlight">{clientSettings.race}</span>,{" "}
              <span className="highlight">{clientSettings.age}</span> years old
              and <span className="highlight">{clientSettings.gender}</span>.
            </p>
            <Button
              onClick={() => setSessionState(SessionState.CLIENT_SETTINGS)}
              color={ButtonColor.TRANSPARENT}
              disabled={!clientSettingsLoad}
              IconRight={FaPencil}
            >
              Change Client
            </Button>
          </div>
          <h4>Evaluation Criteria</h4>
          <ul>
            <li>
              Ability to help the client accurately identify automatic thoughts.
            </li>
            <li>
              capacity to facilitate the client’s understanding of the link
              between thoughts, emotions, and behaviors.
            </li>
          </ul>
        </div>
        <div className={classNames("buttons flex", { show: !!selectedSkill })}>
          <Button
            onClick={() => setSessionState(SessionState.INITIATE_SESSION)}
            size={ButtonSize.LARGE}
            color={ButtonColor.DARK}
            IconRight={FaAnglesRight}
            disabled={!clientSettingsLoad}
            classes="start"
            actionColor={true}
          >
            Start Session
          </Button>
        </div>
      </div>
    ),
    [setSessionState, clientSettings, selectedSkill, clientSettingsLoad],
  );

  useEffect(() => {
    gEvent(
      startSessionView ? "show-start-session-view" : "show-skill-selector",
    );
  }, [startSessionView]);

  return (
    <div className="objectives flex no-flex-mobile">
      <div className="side left">
        <h1>Session Objectives</h1>
        <div className="row">
          <div className="text">
            Thank you for trying out <span className="fydelity">Fydelity</span>.
            The goal of <span className="fydelity">Fydelity</span> is to provide
            you the tools to practice critical skills related to EBTs and
            provide the kind of feedback you would get from direct supervision.
          </div>
          <div className="messages-left">
            Messages left <span>{availableUsage}</span>
          </div>
        </div>
      </div>
      <div className="side right">
        {!!selectedSkill ? (
          startSessionView
        ) : (
          <SkillSelector setSelectedSkill={setSelectedSkill} />
        )}
      </div>
    </div>
  );
};
