import React, { useEffect, useMemo, useState } from "react";
// @ts-ignore
import exitIntent from "exit-intent";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Modal from "react-modal";
import { Button, ButtonColor } from "../theme/Button";
import { useRecoilValue } from "recoil";
import { authAtom } from "../../atom/authAtom";
import { useDoAuth } from "../auth/useDoAuth";
import { gEvent } from "../../util/gtag";

const customStyles = {
  content: {
    top: "30%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    width: "300px",
    margin: "100px auto",
    padding: "20px 50px",
    background: "white",
    "border-radius": "16px",
    "text-align": "center",
    "box-shadow": "0 0 11px 1px rgb(0 0 0 / 20%)",
  },
};

export const useExitIntentModal = () => {
  const [showModal, setShowModal] = useState(false);
  const { isAuth, isLoadingAuth } = useRecoilValue(authAtom);
  const { pathname } = useLocation();
  const { isError, doAuth } = useDoAuth();
  const [demoId, setDemoId] = useState("");
  const navigate = useNavigate();

  const removeExitIntent = useMemo(
    () =>
      exitIntent({
        threshold: 150,
        maxDisplays: 1,
        eventThrottle: 2000,
        onExitIntent: () => {
          // We currently disabled it.
          // setShowModal(true);
        },
      }),
    [setShowModal],
  );

  useEffect(() => {
    if (isAuth || isLoadingAuth || pathname !== "/" || showModal) {
      removeExitIntent();
    }
  }, [pathname, showModal, removeExitIntent, isAuth, isLoadingAuth]);

  const handleAuth = async () => {
    gEvent("auth-using-exit-intent");
    const isAuth = await doAuth(demoId);
    if (isAuth) {
      navigate("/session");
    }
  };

  return (
    <Modal isOpen={showModal} style={customStyles} className="demo-exit-intent">
      <h2>Try Fydelity</h2>
      <p>If you are one of our trusted partners, use the Demo Id.</p>
      {isError && (
        <div style={{ color: "red", fontSize: "12px", marginBottom: "4px" }}>
          Demo ID does not exist
        </div>
      )}
      <input
        value={demoId}
        onChange={(e) => setDemoId(e.target.value)}
        disabled={isLoadingAuth}
        placeholder="Demo ID"
        style={{
          border: "1px solid var(--brand-color)",
          borderRadius: "8px",
          borderTopRightRadius: 0,
          borderBottomRightRadius: 0,
          borderRight: "unset",
        }}
      />
      <Button
        disabled={!demoId.length || isLoadingAuth}
        onClick={handleAuth}
        color={ButtonColor.BRAND}
        style={{
          borderTopLeftRadius: 0,
          borderBottomLeftRadius: 0,
          border: "1px solid var(--brand-color)",
        }}
      >
        Try
      </Button>
      <p>
        Looking to join the demo? <Link to="/contact">Contact us</Link>.
      </p>
      <Button
        color={ButtonColor.TRANSPARENT}
        onClick={() => {
          gEvent("close-exit-intent");
          setShowModal(false);
        }}
      >
        No thanks
      </Button>
    </Modal>
  );
};
