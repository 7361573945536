import { atom } from "recoil";
import { Settings } from "../model/settings";
import { persistenceAtomStorage } from "../util/persistenceSettingsStorage";

export const settingsAtom = atom<Settings>({
  key: "settings",
  default: {
    // @ts-ignore
    voice:
      speechSynthesis.getVoices().find((voice) => voice.default)?.voiceURI ??
      "",
    voiceEnabledByDefault: true,
  },
  effects: [persistenceAtomStorage("settings")],
});
