import { ClientSettings, EvalCriteria, Message } from "./fydelity";

export enum SessionState {
  INITIATE_SESSION,
  LOADING_DATA,
  CLIENT_SETTINGS,
  OBJECTIVES,
  WAITING_USER_INPUT,
  USER_RECORDING,
  USER_REVIEW_MESSAGE,
  SEND_USER_MESSAGE,
  COMPUTER_SPEAK,
  REVIEW,
}

export interface Chat {
  isLoading: boolean;
  messages: Message[];
  userInput: string;
  feedback: EvalCriteria[];
  isDebugChat: boolean;
  isError: boolean;
}

export interface WebClientSettings extends ClientSettings {
  isLoaded: boolean;
}

export interface Session {
  state: SessionState;
  client: WebClientSettings;
  chat: Chat;
  sessionId: string | null;
  isActive: boolean;
}

export const defaultClient = {
  name: "",
  race: "",
  age: "",
  personality: "",
  diagnostic: "",
  demoId: "",
  gender: "",
  id: "",
  isDefault: false,
  isLoaded: false,
  default: false,
};

export const defaultSession: Session = {
  state: SessionState.OBJECTIVES,
  client: defaultClient,
  sessionId: null,
  isActive: false,
  chat: {
    isLoading: false,
    messages: [],
    userInput: "",
    feedback: [],
    isDebugChat: false,
    isError: false,
  },
};
