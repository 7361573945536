import {
  defaultClient,
  defaultSession,
  Session,
  SessionState,
} from "../model/session";
import { Message } from "../model/fydelity";

const sessionStorageKey = "session";
export const debugMessagesStorageKey = "debugMessages";

export const sessionSessionLocalStorageEffect =
  () =>
  ({ setSelf, onSet }: any) => {
    const savedValue = localStorage.getItem(sessionStorageKey);
    if (savedValue != null) {
      const savedSession = JSON.parse(savedValue) as Session;
      const client = savedSession.client ?? defaultClient;
      let session: Session = {
        ...defaultSession,
        client,
        state: SessionState.WAITING_USER_INPUT,
      };

      const savedDebugMessages = localStorage.getItem(debugMessagesStorageKey);
      if (savedDebugMessages !== null) {
        const debugMessages = JSON.parse(savedDebugMessages) as Message[];
        session.chat = {
          ...session.chat,
          messages: debugMessages,
          isDebugChat: true,
        };
      }
      setSelf(session);
    }

    onSet((newValue: any, _: any, isReset: any) => {
      isReset
        ? localStorage.removeItem(sessionStorageKey)
        : localStorage.setItem(sessionStorageKey, JSON.stringify(newValue));
    });
  };
