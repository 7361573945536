import { apiInstance } from "./baseApi";
import {
  EvalCriteria,
  Message,
  MessageFromUserRequest,
  SessionModel,
} from "../model/fydelity";

class MessagesApi {
  async create() {
    try {
      const messages = await apiInstance.instance
        .get<SessionModel>("messages/create")
        .then((r) => r.data);
      if (!messages) {
        throw new Error("Missing client");
      }

      return messages;
    } catch (e) {
      throw new Error(e?.toString());
    }
  }

  send(sessionId: string, text: string): Promise<Message> {
    const messageFromUserRequest: MessageFromUserRequest = {
      text,
      sessionId,
    };

    const message = apiInstance.instance
      .post<Message>("messages/from-user", messageFromUserRequest)
      .then((r) => r.data);
    if (!message) {
      throw new Error("Missing message");
    }

    return message;
  }

  async getFeedback(sessionId: string): Promise<EvalCriteria[]> {
    try {
      const feedback = await apiInstance.instance
        .get<EvalCriteria[]>(`feedback/${sessionId}`)
        .then((r) => r.data);
      if (!feedback) {
        throw new Error("Missing client");
      }

      return feedback;
    } catch (e) {
      throw new Error(e?.toString());
    }
  }
}

export const messagesApi = new MessagesApi();
