import React from "react";
import { useRecoilValue } from "recoil";
import { authAtom } from "../../atom/authAtom";
import { Link } from "react-router-dom";
import { gEvent } from "../../util/gtag";

export const NoAccess = () => {
  const { availableUsage, demoId } = useRecoilValue(authAtom);
  if (availableUsage < 1) {
    gEvent("no-access", { demoId });
    return (
      <div className="no-access">
        <h1>Message limit</h1>
        <div>
          You have reached the limit of messages and we appreciate you trying
          out platform. If you would like to continue using Fydality, please{" "}
          <Link to="/contact">contact us</Link>.
        </div>
        <img src="/art/dog.png" />
      </div>
    );
  }

  return null;
};
