import React, { useCallback, useState } from "react";
import { SessionSkill } from "../../model/fydelity";
import { SetterOrUpdater } from "recoil";
import classNames from "classnames";
import { FaAngleDown, FaBoltLightning, FaLock } from "react-icons/fa6";
import { Tooltip } from "react-tooltip";
import { Button, ButtonColor } from "../theme/Button";
import { gEvent } from "../../util/gtag";

const SUPPORTED = SessionSkill.IAT;

const getSkillName = (skill: SessionSkill) => {
  switch (skill) {
    case SessionSkill.IAT:
      return "Identifying Automatic Thoughts";
    case SessionSkill.CCD:
      return "Challenging Cognitive Distortions ";
    case SessionSkill.EBA:
      return "Establishing Behavioral Activation ";
    case SessionSkill.ET:
      return "Exposure Techniques ";
    case SessionSkill.EER:
      return "Enhancing Emotional Regulation ";
    case SessionSkill.RP:
      return "Relapse Prevention ";
    default:
      throw new Error("Can't map " + SessionSkill);
  }
};

const getSkillText = (skill: SessionSkill) => {
  switch (skill) {
    case SessionSkill.IAT:
      return "Students will practice guiding clients through the process of tracking their automatic thoughts in response to select situations, identifying the emotions and behaviors that follow.";
    case SessionSkill.CCD:
      return "Students will practice using Socratic questioning to challenge clients’ irrational beliefs and cognitive distortions, encouraging them to view situations from different perspectives.";
    case SessionSkill.EBA:
      return "Students will practice working with clients to schedule activities that are designed to counteract anhedonia and improve mood, starting with simple tasks and progressively adding more complex or challenging activities.";
    case SessionSkill.ET:
      return "Students will practice assisting clients in creating a fear hierarchy and supporting them through graduated exposure to feared stimuli or situations.";
    case SessionSkill.EER:
      return "Students will practice teaching clients relaxation techniques (like deep breathing, progressive muscle relaxation) and mindfulness practices.";
    case SessionSkill.RP:
      return "Students will practice working with clients to identify potential relapse triggers and create strategies to maintain therapeutic gains.\n";
    default:
      throw new Error("Can't map " + skill);
  }
};

interface SkillSelectorProps {
  setSelectedSkill: SetterOrUpdater<SessionSkill | null>;
}

export const SkillSelector = ({ setSelectedSkill }: SkillSelectorProps) => {
  const [openSkill, setOpenSkill] = useState<SessionSkill | null>(SUPPORTED);

  const skillItem = useCallback(
    (skill: SessionSkill) => {
      const isSelected = openSkill === skill;
      const isSupported = skill === SUPPORTED;
      return (
        <div
          key={skill}
          className={classNames("skill", { selected: isSelected })}
          onClick={() => setOpenSkill(skill === openSkill ? null : skill)}
        >
          <Tooltip id="lock" />
          <h4 className="flex">
            <FaAngleDown className="icon" />
            {getSkillName(skill)}
            {isSelected && (
              <Button
                disabled={!isSupported}
                onClick={() => {
                  gEvent("select skill", skill);
                  setSelectedSkill(skill);
                }}
                color={ButtonColor.DARK}
                IconRight={isSupported ? undefined : FaLock}
                actionColor={true}
              >
                Select
              </Button>
            )}
            {!isSupported && <span className="coming-soon">(coming soon)</span>}
          </h4>
          <div className={"text"}>
            {getSkillText(skill)}
            <div
              data-tooltip-id={isSupported ? "" : "lock"}
              data-tooltip-content="Unavailable in this demo"
              style={{ width: "fit-content" }}
            ></div>
          </div>
        </div>
      );
    },
    [setOpenSkill, openSkill, setSelectedSkill],
  );

  return (
    <div className="skills-selector">
      <div className="skills-list flex">
        <h2>Practice CBT Skills</h2>
        <div>
          We worked with experts and experienced supervisors in CBT to offer the
          following skills.
        </div>
        <h3 className="flex">
          <FaBoltLightning className="skill-icon" />
          Select skill
        </h3>
        {Object.values(SessionSkill).map(skillItem)}
      </div>
    </div>
  );
};
