import React from "react";
import "./forPages.scss";
import "./homepageShared.scss";

export const ForInstitutions = () => {
  return (
    <div className="for-students max-width">
      <h1>For Institutions</h1>
      <div className="flex">
        <div>
          <h2>Elevate Your Clinical Training Program</h2>
          <p className="text">
            Unlock the future of mental healthcare education with Fydelity, the
            AI-driven platform designed to transform clinical training at
            universities and training institutions. By integrating Fydelity into
            your curriculum, you provide your students with an unparalleled
            opportunity to master evidence-based therapies through innovative,
            hands-on practice.
          </p>
        </div>
        <img
          src="/art/art7.png"
          alt="Learn"
          width="500"
          className="mobile-hide image"
        />
      </div>

      <div className="info max-width no-max-width-mobile">
        <h2>Features Tailored for Educational Excellence</h2>
        <div className="flex items-list">
          <div className="item">
            <h3>Customized Learning Experiences</h3>
            <img src="/art/art17-1.png" alt="Learn" />
            <div className="text">
              Offer students a wide range of virtual patient interactions,
              ensuring comprehensive exposure to diverse clinical scenarios
            </div>
          </div>
          <div className="item">
            <h3>Advanced Feedback Mechanisms</h3>
            <img src="/art/art18-1.png" alt="Rehearse" />
            <div className="text">
              Utilize AI-generated feedback to provide students with detailed
              insights into their therapeutic techniques, mirroring the
              invaluable guidance of experienced supervisors
            </div>
          </div>
          <div className="item">
            <h3>Progress Monitoring</h3>
            <img src="/art/art19-1.png" alt="Rehearse" />
            <div className="text">
              Empower educators with robust tracking tools to monitor student
              progress, identify areas for improvement, and celebrate
              achievements in real-time
            </div>
          </div>
        </div>
      </div>

      <div className="why max-width no-max-width-mobile">
        <h2>Why for your institute</h2>
        <div className="why-inner content">
          Fydelity bridges the gap between theoretical knowledge and practical
          application, preparing students for success in the demanding field of
          mental healthcare. Our platform enhances learning outcomes, fosters a
          deeper understanding of therapeutic practices, and prepares students
          for the complexities of real-world clinical settings. By partnering
          with Fydelity, your institution can lead the way in innovative mental
          health education, producing highly skilled professionals ready to make
          a profound impact on the lives of those they serve.
        </div>
      </div>
    </div>
  );
};
