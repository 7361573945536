import React, { useEffect, useMemo } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { Header } from "./Header";
import classNames from "classnames";
import { useRecoilValue } from "recoil";
import { sessionStateState } from "../../atom/sessionAtom";
import { SessionState } from "../../model/session";
import { gEvent } from "../../util/gtag";
import { useResetSessionOnExit } from "../session/useResetSessionOnExit";

export const Page = () => {
  const { search } = useLocation();
  const location = useLocation();
  const sessionState = useRecoilValue(sessionStateState);
  const speedbumpModal = useResetSessionOnExit();

  const isHomepage = useMemo(
    () => location.pathname === "/",
    [location.pathname],
  );

  useEffect(() => {
    document
      .getElementById("outlet")
      ?.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, [location]);

  useEffect(() => {
    if (isHomepage) {
      const source = new URLSearchParams(search).get("source");
      gEvent("homepage", { source });
    }
  }, [search, isHomepage]);

  const isSession = useMemo(
    () => location.pathname.startsWith("/session"),
    [location.pathname],
  );
  const isSessionObjectives = sessionState === SessionState.OBJECTIVES;

  return (
    <div
      className={classNames("page", {
        homepage: !isSession,
        app: isSession,
        "app-session": isSession,
        "session-objectives": isSessionObjectives,
      })}
    >
      {speedbumpModal}
      <Header />
      <div className="outlet" id="outlet">
        <Outlet />
      </div>
    </div>
  );
};
