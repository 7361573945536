import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useAuth } from "../auth/useAuth";
import { debugApi } from "../../api/debugApi";
import { PromptKey, PromptModel } from "../../model/fydelity";
import { DebugPromptData } from "./DebugPromptData";
import "./style.scss";
import { PromptTesterDebug } from "./PromptTesterDebug";

export const DynamicPrompts = () => {
  useAuth(true);
  const [promptsData, setPromptsData] =
    useState<{ [key in PromptKey]: PromptModel }>();
  const [selectedPromptKey, setSelectedPromptKey] = useState<PromptKey | null>(
    null,
  );

  const setPrompts = useCallback(async () => {
    if (promptsData) {
      return;
    }

    const promptsMap = await debugApi.getAllPrompts();
    setPromptsData(promptsMap);
  }, [setPromptsData, promptsData]);

  useEffect(() => {
    setPrompts();
  }, [setPrompts]);

  const promptKeySelector = useMemo(() => {
    return (
      <select
        value={selectedPromptKey ? (selectedPromptKey as string) : ""}
        onChange={(e) => setSelectedPromptKey(e.target.value as PromptKey)}
      >
        <option value=""></option>
        {Object.values(PromptKey).map((key, index) => (
          <option value={key} key={index}>
            {key}
          </option>
        ))}
      </select>
    );
  }, [selectedPromptKey, setSelectedPromptKey]);

  const selectedPrompt = useMemo(() => {
    if (!selectedPromptKey || !promptsData) {
      return null;
    }

    return promptsData[selectedPromptKey];
  }, [promptsData, selectedPromptKey]);

  const promptDetails = useMemo(() => {
    if (!selectedPromptKey || !promptsData || !selectedPrompt) {
      return null;
    }

    const updatePrompt = (newPromptText: string) => {
      const newPrompt = promptsData[selectedPromptKey];
      newPrompt.prompt = newPromptText;
      setPromptsData({ ...promptsData, [selectedPromptKey]: newPrompt });
      debugApi.updatePrompt(selectedPromptKey, newPrompt.prompt);
    };

    return (
      <DebugPromptData
        promptData={selectedPrompt}
        onPromptChange={updatePrompt}
      />
    );
  }, [promptsData, selectedPromptKey, setPromptsData, selectedPrompt]);

  return (
    <div className="prompts-tester flex">
      <div className="prompt-side">
        <h2>Prompt</h2>
        <div>{promptKeySelector}</div>
        <div className="prompt-text">{promptDetails}</div>
      </div>
      <PromptTesterDebug promptKey={selectedPromptKey} />
    </div>
  );
};
