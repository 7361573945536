import React, { useMemo, useState } from "react";
import {
  useRecoilState,
  useRecoilValue,
  useResetRecoilState,
  useSetRecoilState,
} from "recoil";
import {
  chatState,
  sessionAtom,
  sessionStateState,
} from "../../atom/sessionAtom";
import { useNavigate } from "react-router-dom";
import { SessionState } from "../../model/session";
import { Loader } from "./Loader";
import { startReviewFn } from "./SessionControls";
import { EvalCriteria, PromptKey } from "../../model/fydelity";
import classNames from "classnames";
import { Margin, usePDF } from "react-to-pdf";
import { Button, ButtonColor } from "../theme/Button";
import "./evaluation.scss";
import { FaAngleUp, FaDiaspora } from "react-icons/fa";
import { Fa0, Fa1, Fa2, Fa3, FaAngleDown } from "react-icons/fa6";

const breakToParagraphs = (feedback: string) =>
  feedback.split("\n").map((text) => <p>{text}</p>);

const titleMapper = (key: PromptKey) => {
  switch (key) {
    case PromptKey.FEEDBACK_RUBRIC_1:
      return "CTRS-R Item - Agenda";
    case PromptKey.FEEDBACK_RUBRIC_2:
      return "CTRS-R Item - Feedback";
    case PromptKey.FEEDBACK_RUBRIC_3:
      return "CTRS-R Item - Understanding";
    case PromptKey.FEEDBACK_RUBRIC_4:
      return "CTRS-R Item - Interpersonal Effectiveness";
    case PromptKey.FEEDBACK_RUBRIC_5:
      return "CTRS-R Item - Collaboration";
    case PromptKey.FEEDBACK_RUBRIC_6:
      return "CTRS-R Item - Pacing";
    case PromptKey.FEEDBACK_RUBRIC_7:
      return "CTRS-R Item - Guided Discovery";
    case PromptKey.FEEDBACK_RUBRIC_8:
      return "CTRS-R Item - Focus on Key Cognitions and Behaviors";
    case PromptKey.FEEDBACK_RUBRIC_9:
      return "CTRS-R Item - Strategy for Change";
    case PromptKey.FEEDBACK_RUBRIC_10:
      return "CTRS-R Item - Application of CBT Technique";
    case PromptKey.FEEDBACK_RUBRIC_11:
      return "CTRS-R Item - Action Plan";
    default:
      return "CTRS-R Criteria";
  }
};

const scoreIcon = (score: number) => {
  switch (score) {
    case 0:
      return <Fa0 />;
    case 1:
      return <Fa1 />;
    case 2:
      return <Fa2 />;
    default:
      return <Fa3 />;
  }
};

const getScoreClasses = (score: number) => ({
  zero: score === 0,
  one: score === 1,
  two: score === 2,
  three: score === 3,
});

export const EvalView = ({
  promptKey,
  score,
  reasons,
  summary,
}: EvalCriteria) => {
  const [showReasons, setShowReasons] = useState(false);
  return (
    <div className={classNames("box", getScoreClasses(score))}>
      <div className="flex criteria-title">
        <h4 className="flex">
          <FaDiaspora className="icon" />
          {titleMapper(promptKey)}
        </h4>
        <div className="score-wrapper flex">
          <div className="score flex">{scoreIcon(score)}</div>
          score
        </div>
      </div>
      <div className="text">
        <h5>Summary</h5>
        {summary}
      </div>
      <Button
        classes="show-reason"
        onClick={() => setShowReasons((val) => !val)}
        color={ButtonColor.BRAND}
        IconLeft={showReasons ? FaAngleUp : FaAngleDown}
      >
        Show more
      </Button>
      <div className={classNames("reason", { show: showReasons })}>
        <h5>Reasons</h5>
        <ol>
          {reasons.map((r, index) => (
            <li key={index}>{r}</li>
          ))}
        </ol>
      </div>
    </div>
  );
};

export const FeedbackView = () => {
  const [{ feedback, isError }, setChat] = useRecoilState(chatState);
  const resetSession = useResetRecoilState(chatState);
  const setSessionState = useSetRecoilState(sessionStateState);
  const { sessionId } = useRecoilValue(sessionAtom);
  const navigate = useNavigate();
  const { toPDF, targetRef } = usePDF({
    method: "save",
    filename: "session.pdf",
    page: { margin: Margin.MEDIUM },
  });

  const handleReset = () => {
    resetSession();
    setSessionState(SessionState.INITIATE_SESSION);
    navigate("/");
  };

  const buttonsEl = useMemo(
    () => (
      <div className="flex buttons">
        <Button
          onClick={handleReset}
          classes="finish"
          color={ButtonColor.BRAND}
        >
          Finish
        </Button>
        <Button
          onClick={() => toPDF()}
          classes="finish"
          color={ButtonColor.DARK}
        >
          Download
        </Button>
      </div>
    ),
    [handleReset, toPDF],
  );

  if (isError) {
    if (!sessionId) {
      throw new Error("Missing session id");
    }

    return (
      <div className="feedback-error">
        <h2>Ops...</h2>
        <img src="/art/dog.png" />
        <p>We had an issue generating feedback.</p>
        <button onClick={() => startReviewFn(sessionId, setChat)}>
          Try again
        </button>
      </div>
    );
  }

  if (!feedback?.length) {
    return (
      <Loader
        text="Evaluating Conversation"
        smallText="That might take a bit"
        estimatedTime={55}
      />
    );
  }

  return (
    <div className="feedback flex">
      {buttonsEl}
      <div ref={targetRef} className="pdf-frame">
        <h2>Session Evaluation</h2>
        {/*<div className="note flex">*/}
        {/*    <div className="label">NOTE</div>*/}
        {/*    Our evaluation feature is still under active development. Some of the*/}
        {/*    feedback is either mock or incorrect. We are working to bring our users*/}
        {/*    the most accurate level of evaluation.*/}
        {/*</div>*/}
        <div className="written flex">
          {feedback.map((val) => (
            <EvalView {...val} />
          ))}
        </div>
      </div>
      {buttonsEl}
    </div>
  );
};
