import { createBrowserRouter } from "react-router-dom";
import { Page } from "./Page";
import { SessionController } from "../session/SessionController";
import { VoiceSettings } from "../settings/VoiceSettings";
import { DebugChat } from "../debug/DebugChat";
import { Homepage } from "../homepage/Homepage";
import { Auth } from "../auth/Auth";
import { Contact } from "../contact/Contact";
import { DebugDemoId } from "../debug/DebugDemoId";
import { NoAccess } from "../auth/NoAccess";
import { DynamicPrompts } from "../debug/DynamicPrompts";
import { ForStudents } from "../homepage/ForStudents";
import { ForInstitutions } from "../homepage/ForInstitutions";
import { ExampleEval } from "../homepage/ExampleEval";
import { ForProviders } from "../homepage/ForProviders";

export const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <>
        <Page />
      </>
    ),
    children: [
      { path: "", element: <Homepage /> },
      { path: "for-students", element: <ForStudents /> },
      { path: "for-institutions", element: <ForInstitutions /> },
      { path: "for-providers", element: <ForProviders /> },
      { path: "example-eval", element: <ExampleEval /> },
      { path: "access", element: <NoAccess /> },
      { path: "demo", element: <Auth /> },
      { path: "session", element: <SessionController /> },
      { path: "settings", element: <VoiceSettings /> },
      { path: "contact", element: <Contact /> },
      { path: "debug/prompts", element: <DynamicPrompts /> },
      { path: "debug/chat", element: <DebugChat /> },
      { path: "debug/demoId", element: <DebugDemoId /> },
    ],
  },
]);
