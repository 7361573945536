import React from "react";
import { UserTextInput } from "./UserTextInput";
import { SessionControls } from "./SessionControls";
import { SpeakingButton } from "./SpeakingButton";

export const SessionInput = () => {
  return (
    <div className="inputs">
      <SpeakingButton />
      <UserTextInput />
      <SessionControls />
    </div>
  );
};
