import React from "react";
import { VoiceOver } from "./VoiceOver";
import { SessionInput } from "./SessionInput";
import { Chat } from "./Chat";
import "./style.scss";

export const SessionView = () => {
  return (
    <div className="session-page">
      <VoiceOver />
      <Chat />
      <SessionInput />
    </div>
  );
};
