import React, { useEffect, useMemo, useState } from "react";
import "./voiceanimation.css";
import { useRecoilState, useRecoilValue } from "recoil";
import { computerMessages, sessionStateState } from "../../atom/sessionAtom";
import { SessionState } from "../../model/session";
import { settingsAtom } from "../../atom/settingsAtom";
import { useLocation } from "react-router-dom";
import classNames from "classnames";
import { Tooltip } from "react-tooltip";
import { VoiceSettings } from "../settings/VoiceSettings";

const Bar = ({ play }: { play: boolean }) => (
  <div className={`bar ${play ? "play" : ""}`}></div>
);

export const VoiceOver = () => {
  const [sessionState, setSessionState] = useRecoilState(sessionStateState);
  const messages = useRecoilValue(computerMessages);
  const [{ voiceEnabledByDefault, voice }, setSettings] =
    useRecoilState(settingsAtom);
  const [play, setPlay] = useState(false);
  const location = useLocation();
  const [voiceEnabled, setVoiceEnabled] = useState<boolean>(
    voiceEnabledByDefault,
  );

  const currentVoice = useMemo(() => {
    const voices = speechSynthesis.getVoices();
    const defaultVoice = voices.find((v) => v.default);
    return voices.find((v) => v.voiceURI === voice) ?? defaultVoice;
  }, [voice]);

  const speech = useMemo<SpeechSynthesisUtterance | null>(() => {
    if (!voiceEnabled && sessionState === SessionState.COMPUTER_SPEAK) {
      setPlay(false);
      setSessionState(SessionState.WAITING_USER_INPUT);
    }

    if (!voiceEnabled || !currentVoice || messages.length === 0) {
      return null;
    }

    const speech = new SpeechSynthesisUtterance();
    speech.text = messages[messages.length - 1].text;
    speech.voice = currentVoice;
    speech.onstart = () => {
      setPlay(true);
    };
    speech.onend = () => {
      setPlay(false);
      setSessionState(SessionState.WAITING_USER_INPUT);
    };
    return speech;
  }, [
    voiceEnabled,
    currentVoice,
    messages,
    setSessionState,
    setPlay,
    sessionState,
  ]);

  useEffect(() => {
    if (
      sessionState === SessionState.REVIEW ||
      location.pathname.startsWith("/session/chat") ||
      !voiceEnabled
    ) {
      speechSynthesis.cancel();
    }
  }, [sessionState, location, voiceEnabled]);

  useEffect(() => {
    if (sessionState === SessionState.COMPUTER_SPEAK) {
      if (!speech) {
        return;
      }

      window.speechSynthesis.speak(speech);
      setPlay(true);
    }
  }, [
    sessionState,
    setSessionState,
    messages,
    currentVoice,
    speech,
    messages,
    voiceEnabled,
  ]);

  const voiceTooltip = useMemo(() => {
    if (voiceEnabled) {
      return <VoiceSettings />;
    }

    return null;
  }, [voiceEnabled]);

  const settingsIcons = useMemo(() => {
    return (
      <>
        <a data-tooltip-id="settings-tooltip">
          <img
            src="/icon/voice-icon.png"
            className={classNames("settings-icon", { active: voiceEnabled })}
            alt="Button to enable and disabled voice over"
            onClick={() => {
              setVoiceEnabled((voice) => !voice);
              setSettings((settings) => ({
                ...settings,
                voiceEnabledByDefault: !voiceEnabled,
              }));
            }}
          />
        </a>
      </>
    );
  }, [voiceEnabled, setVoiceEnabled, setSettings]);

  return (
    <div className="voice-over">
      <Tooltip
        id="settings-tooltip"
        className="settings-tooltip"
        clickable={true}
      >
        {voiceTooltip}
      </Tooltip>
      <div className="settings-panel flex">{settingsIcons}</div>
      {voiceEnabled && (
        <div className="voice-animation">
          <Bar play={play} />
          <Bar play={play} />
          <Bar play={play} />
          <Bar play={play} />
          <Bar play={play} />
          <Bar play={play} />
          <Bar play={play} />
          <Bar play={play} />
        </div>
      )}
    </div>
  );
};
